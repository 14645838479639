import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';

import MainCard from 'components/MainCard';
import axiosInstance from 'utils/axios.config';
import CustomChip from 'components/Chip/CustomChip';
import LeaveApprovalLoader from 'loader/LeaveApprovalLoader';
import LoanApprovalModal from './LoanApprovalModal';

export default function LoanApproval() {
  const [loanApproved, setLoanApproved] = useState([]);
  console.log( 'loanApproved' , loanApproved);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [loanInfo, setLoanInfo] = useState('');

  const handleClose = () => setOpen(false);

  const handleOpen = (lrsl) => {
    setLoanInfo(lrsl);
    setOpen(true);
  };

  useEffect(() => {
    axiosInstance
      .get('https://api.hellokompass.com/payroll/loanapplylist')
      .then((res) => {
        setLoanApproved(res.data.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Box>
      <MainCard>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', pb: 2 }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              fontSize: '18px',
              color: '#333'
            }}
          >
            Leave Approval
          </Typography>
        </Box>

        <Box mt={3}>
          {loading ? (
            <Box display="flex" justifyContent="center" mt={4}>
              <LeaveApprovalLoader></LeaveApprovalLoader>
            </Box>
          ) : loanApproved.length === 0 ? (
            <Box
              sx={{
                textAlign: 'center',
                background: '#f8f8f8',
                padding: '20px',
                borderRadius: '10px',
                border: '1px solid #ddd'
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  fontFamily: 'Poppins',
                  color: '#888'
                }}
              >
                No Leave Applications Available
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {loanApproved.map((loan) => (
                <Grid item xs={12} sm={6} md={4} key={loan?.lrsl}>
                  <Box
                    sx={{
                      border: '1px solid #f1f1f1',
                      background: '#f8f8f8',
                      p: 2,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      cursor: 'pointer',
                      mt: 1,
                      borderRadius: '10px',
                      '&:hover': {
                        background: 'rgba(18, 169, 178, 0.1)'
                      }
                    }}
                    onClick={() => handleOpen(loan)}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                          color: '#333'
                        }}
                      >
                        From: {loan?.first_name} {loan?.last_name}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontFamily: 'Poppins',
                          fontSize: '13px',
                          color: '#333'
                        }}
                      >
                        Amount: {loan?.lon_amt} days
                      </Typography>
                    
                    </Box>
                    <Box>
                      <CustomChip>{loan?.lon_status}</CustomChip>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        {/* Leave Modal */}
        <LoanApprovalModal open={open} handleClose={handleClose} loanInfo={loanInfo} />
      </MainCard>
    </Box>
  );
}
