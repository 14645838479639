import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';

import MainCard from 'components/MainCard';
import axiosInstance from 'utils/axios.config';
import moment from 'moment';
import CustomChip from 'components/Chip/CustomChip';
import LeaveApprovalLoader from 'loader/LeaveApprovalLoader';
import IOUApprovalModal from './IOUApprovalModal';

export default function IOUApprovalList() {
    const [iouApproved, setIouApproved] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [iouInfo, setIouInfo] = useState('');
  
    const handleClose = () => setOpen(false);
  
    const handleOpen = (lrsl) => {
        setIouInfo(lrsl);
      setOpen(true);
    };
  
    useEffect(() => {
      axiosInstance
        .get('https://api.hellokompass.com/payroll/iouapplylist')
        .then((res) => {
            setIouApproved(res.data.data);
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    }, []);
  return (
     <Box>
          <MainCard>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', pb: 2 }}>
              <Typography
                sx={{
                  fontWeight: 'bold',
                  fontFamily: 'Poppins',
                  fontSize: '18px',
                  color: '#333'
                }}
              >
                IOU Approval
              </Typography>
            </Box>
    
            <Box mt={3}>
              {loading ? (
                <Box display="flex" justifyContent="center" mt={4}>
                  <LeaveApprovalLoader></LeaveApprovalLoader>
                </Box>
              ) : iouApproved.length === 0 ? (
                <Box
                  sx={{
                    textAlign: 'center',
                    background: '#f8f8f8',
                    padding: '20px',
                    borderRadius: '10px',
                    border: '1px solid #ddd'
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 'bold',
                      fontFamily: 'Poppins',
                      color: '#888'
                    }}
                  >
                    No Leave Applications Available
                  </Typography>
                </Box>
              ) : (
                <Grid container spacing={2}>
                  {iouApproved.map((iou) => (
                    <Grid item xs={12} sm={6} md={4} key={iou?.iou_app_sl}>
                      <Box
                        sx={{
                          border: '1px solid #f1f1f1',
                          background: '#f8f8f8',
                          p: 2,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          cursor: 'pointer',
                          mt: 1,
                          borderRadius: '10px',
                          '&:hover': {
                            background: 'rgba(18, 169, 178, 0.1)'
                          }
                        }}
                        onClick={() => handleOpen(iou)}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Poppins',
                              fontSize: '14px',
                              color: '#333'
                            }}
                          >
                            From: {iou?.first_name} {iou?.last_name}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Poppins',
                              fontSize: '13px',
                              color: '#333'
                            }}
                          >
                            Amount: {iou?.iou_amount} 
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              fontFamily: 'Poppins',
                              fontSize: '13px',
                              color: '#333'
                            }}
                          >
                            Applied Date: {moment(iou?.lev_applydt).format('D MMM YYYY')}
                          </Typography>
                        </Box>
                        <Box>
                          <CustomChip>{iou?.iou_status}</CustomChip>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
    
            {/* Leave Modal */}
            <IOUApprovalModal open={open} handleClose={handleClose} iouInfo={iouInfo} />
          </MainCard>
        </Box>
  )
}
