import React from 'react';
import { Box, Typography, Modal, Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import moment from 'moment';

import axiosInstance from 'utils/axios.config';
import CustomChip from 'components/Chip/CustomChip';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4
};

export default function IOUApprovalModal({ open, handleClose, iouInfo }) {
  const { iou_app_sl, first_name, last_name, designation, email, lev_approval, phone, lev_from, lev_to, iou_amount, iou_reason } = iouInfo;
  const levFrom = moment(lev_from).format('D MMM YYYY');
  const levTo = moment(lev_to).format('D MMM YYYY');
  const { register, handleSubmit, reset, setValue } = useForm();
  const onSubmit = (data) => {
    axiosInstance
      .post('https://api.hellokompass.com/payroll/iouupdate', data)
      .then((res) => {
        if ((res.status = 200)) {
          toast.success(res.data.message);
          reset();
          handleClose();
        } else if ((res.status = 400)) {
          toast.error(res.data.message);
        } else {
          <></>;
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <Box>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ border: '1px solid #f1f1f1', p: 2, borderRadius: '10px' }}>
            <Box sx={{ boxShadow: 2, p: 2, borderRadius: '10px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'end', mb: 2 }}>
                <Typography>
                  <CustomChip>{lev_approval}</CustomChip>
                </Typography>
              </Box>
              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                From : {first_name + ' ' + last_name}
              </Typography>

              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                Designation : {designation}
              </Typography>
              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                Email : {email}
              </Typography>
              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                Phone : {phone}
              </Typography>
            </Box>
            <Box sx={{ boxShadow: 2, p: 2, borderRadius: '10px' }}>
              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                Amount : {iou_amount}
              </Typography>

              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                Reason : {iou_reason}
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ mt: 1 }}>
                <Box hidden>
                  {iou_app_sl && (
                    <TextField value={iou_app_sl} variant="outlined" {...register('iou_id', { required: true })} sx={{ width: '100%' }} />
                  )}
                </Box>

                <TextField
                  variant="outlined"
                  name="notez"
                  minRows={2}
                  multiline
                  sx={{ width: '100%', borderRadius: '10px' }}
                  {...register('notez', { required: true })}
                  placeholder="Write your note"
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 1, justifyContent: 'end', mt: 2 }}>
                <Box>
                  <Button
                    type="submit"
                    {...register('status')}
                    onClick={() => setValue('status', 'canceled')}
                    sx={{
                      background: '#12a9b2',
                      color: '#fff',
                      px: 4,
                      borderRadius: 0,
                      '&:hover': {
                        background: '#0e8f98'
                      }
                    }}
                  >
                    Decline
                  </Button>
                </Box>
                <Box>
                  <Button
                    type="submit"
                    {...register('status')}
                    onClick={() => setValue('status', 'approved')}
                    sx={{
                      background: '#12a9b2',
                      color: '#fff',
                      px: 4,
                      borderRadius: 0,
                      '&:hover': {
                        background: '#0e8f98'
                      }
                    }}
                  >
                    Accept
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
