import React from 'react';
import { Box, Typography, Modal, Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import moment from 'moment';

import axiosInstance from 'utils/axios.config';
import CustomChip from 'components/Chip/CustomChip';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4
};

export default function LoanApprovalModal({ open, handleClose, loanInfo }) {
  const {
    plnsl,
    first_name,
    last_name,
    designation,
    email,
    lon_status,
    phone,
    lon_tot_amt,
    lon_reason,
    type_name,
    lon_amt,
    type_interest,
    emi_amount,
    emi_length,
    regdate
  } = loanInfo;

  const loanFrom = moment(regdate).format('D MMM YYYY');

  const { register, handleSubmit, reset, setValue } = useForm();
  const onSubmit = (data) => {
    axiosInstance
      .post('https://api.hellokompass.com/payroll/loanupdate', data)
      .then((res) => {
        if ((res.status = 200)) {
          toast.success(res.data.message);
          reset();
          handleClose();
        } else if ((res.status = 400)) {
          toast.error(res.data.message);
        } else {
          <></>;
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <Box>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ border: '1px solid #f1f1f1', p: 2, borderRadius: '10px' }}>
            <Box sx={{ boxShadow: 2, p: 2, borderRadius: '10px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'end', mb: 2 }}>
                <Typography>
                  <CustomChip>{lon_status}</CustomChip>
                </Typography>
              </Box>
              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                From : {first_name + ' ' + last_name}
              </Typography>

              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                Designation : {designation}
              </Typography>
              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                Email : {email}
              </Typography>
              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                Phone : {phone}
              </Typography>
            </Box>
            <Box sx={{ boxShadow: 2, p: 2, borderRadius: '10px' }}>
              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                Type : {type_name}
              </Typography>
              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                Amount : {lon_amt}
              </Typography>
              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                Interest Rate : {type_interest}
              </Typography>
              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                Total Amount : {lon_tot_amt}
              </Typography>
              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                EMI Length : {emi_length}
              </Typography>
              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                EMI Amount : {emi_amount}
              </Typography>
              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                Date : {loanFrom}
              </Typography>
              <Typography sx={{ fontWeight: 'medium', pb: '5px', fontFamily: 'Poppins', fontSize: '13px', color: '#333' }}>
                Reason : {lon_reason}
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ mt: 1 }}>
                <Box hidden>
                  {plnsl && <TextField value={plnsl} variant="outlined" {...register('lon_id', { required: true })} sx={{ width: '100%' }} />}
                </Box>

                <TextField
                  variant="outlined"
                  name="comment"
                  minRows={2}
                  multiline
                  sx={{ width: '100%', borderRadius: '10px' }}
                  {...register('comment', { required: true })}
                  placeholder="Write your Reason"
                />
              </Box>
              <Box sx={{ display: 'flex', gap: 1, justifyContent: 'end', mt: 2 }}>
                <Box>
                  <Button
                    type="submit"
                    {...register('status')}
                    onClick={() => setValue('status', 'declined')}
                    sx={{
                      background: '#12a9b2',
                      color: '#fff',
                      px: 4,
                      borderRadius: 0,
                      '&:hover': {
                        background: '#0e8f98'
                      }
                    }}
                  >
                    Decline
                  </Button>
                </Box>
                <Box>
                  <Button
                    type="submit"
                    {...register('status')}
                    onClick={() => setValue('status', 'approved')}
                    sx={{
                      background: '#12a9b2',
                      color: '#fff',
                      px: 4,
                      borderRadius: 0,
                      '&:hover': {
                        background: '#0e8f98'
                      }
                    }}
                  >
                    Accept
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
