import React from 'react';
import { Box, Grid, Typography, Skeleton } from '@mui/material';
export default function LeaveApprovalLoader() {
  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      <Grid item xs={4}>
        <Box sx={{ boxShadow: 1, p: 1, borderRadius: '10px' }}>
          <Box m={1} display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Box gap={2}>
                <Typography variant="body2" sx={{ py: '2px' }}>
                  <Skeleton width="200px" height={25} />
                </Typography>
                <Typography variant="body2" sx={{ py: '2px' }}>
                  <Skeleton width="150px" height={25} />
                </Typography>
                <Typography variant="body2" sx={{ py: '2px' }}>
                  <Skeleton width="200px" height={25} />
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap="8px">
              <Skeleton width="100px" height={20} />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ boxShadow: 1, p: 1, borderRadius: '10px' }}>
          <Box m={1} display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Box gap={2}>
                <Typography variant="body2" sx={{ py: '4px' }}>
                  <Skeleton width="200px" height={25} />
                </Typography>
                <Typography variant="body2" sx={{ py: '4px' }}>
                  <Skeleton width="150px" height={25} />
                </Typography>
                <Typography variant="body2" sx={{ py: '4px' }}>
                  <Skeleton width="200px" height={25} />
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap="8px">
              <Skeleton width="100px" height={20} />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ boxShadow: 1, p: 1, borderRadius: '10px' }}>
          <Box m={1} display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Box gap={2}>
                <Typography variant="body2" sx={{ py: '4px' }}>
                  <Skeleton width="200px" height={25} />
                </Typography>
                <Typography variant="body2" sx={{ py: '4px' }}>
                  <Skeleton width="150px" height={25} />
                </Typography>
                <Typography variant="body2" sx={{ py: '4px' }}>
                  <Skeleton width="200px" height={25} />
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap="8px">
              <Skeleton width="100px" height={20} />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ boxShadow: 1, p: 1, borderRadius: '10px' }}>
          <Box m={1} display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Box gap={2}>
                <Typography variant="body2" sx={{ py: '4px' }}>
                  <Skeleton width="200px" height={25} />
                </Typography>
                <Typography variant="body2" sx={{ py: '4px' }}>
                  <Skeleton width="150px" height={25} />
                </Typography>
                <Typography variant="body2" sx={{ py: '4px' }}>
                  <Skeleton width="200px" height={25} />
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap="8px">
              <Skeleton width="100px" height={20} />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ boxShadow: 1, p: 1, borderRadius: '10px' }}>
          <Box m={1} display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Box gap={2}>
                <Typography variant="body2" sx={{ py: '4px' }}>
                  <Skeleton width="200px" height={25} />
                </Typography>
                <Typography variant="body2" sx={{ py: '4px' }}>
                  <Skeleton width="150px" height={25} />
                </Typography>
                <Typography variant="body2" sx={{ py: '4px' }}>
                  <Skeleton width="200px" height={25} />
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap="8px">
              <Skeleton width="100px" height={20} />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{ boxShadow: 1, p: 1, borderRadius: '10px' }}>
          <Box m={1} display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Box gap={2}>
                <Typography variant="body2" sx={{ py: '4px' }}>
                  <Skeleton width="200px" height={25} />
                </Typography>
                <Typography variant="body2" sx={{ py: '4px' }}>
                  <Skeleton width="150px" height={25} />
                </Typography>
                <Typography variant="body2" sx={{ py: '4px' }}>
                  <Skeleton width="200px" height={25} />
                </Typography>
              </Box>
            </Box>
            <Box display="flex" gap="8px">
              <Skeleton width="100px" height={20} />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
