import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid } from '@mui/material';

import MainCard from 'components/MainCard';
import axiosInstance from 'utils/axios.config';
import CustomChip from 'components/Chip/CustomChip';
import LeaveApprovalLoader from 'loader/LeaveApprovalLoader';
import EarnWageModal from './EarnWageModal';
export default function EarnWageApprovalList() {

    const [earnWageApproved, setEarnWageApproved] = useState([]);
    console.log( 'earnWageApproved' , earnWageApproved);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [earnWageInfo, setEarnWageInfo] = useState('');
  
    const handleClose = () => setOpen(false);
  
    const handleOpen = (ewage_sl) => {
        setEarnWageInfo(ewage_sl );
        setOpen(true);
    };
  
    useEffect(() => {
      axiosInstance
        .get('https://api.hellokompass.com/payroll/wageapply')
        .then((res) => {
            setEarnWageApproved(res.data.data);
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    }, []);



  return (
      <Box>
        <MainCard>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', pb: 2 }}>
            <Typography
              sx={{
                fontWeight: 'bold',
                fontFamily: 'Poppins',
                fontSize: '18px',
                color: '#333'
              }}
            >
              Earned Wage Approval
            </Typography>
          </Box>
  
          <Box mt={3}>
            {loading ? (
              <Box display="flex" justifyContent="center" mt={4}>
                <LeaveApprovalLoader></LeaveApprovalLoader>
              </Box>
            ) : earnWageApproved.length === 0 ? (
              <Box
                sx={{
                  textAlign: 'center',
                  background: '#f8f8f8',
                  padding: '20px',
                  borderRadius: '10px',
                  border: '1px solid #ddd'
                }}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    fontFamily: 'Poppins',
                    color: '#888'
                  }}
                >
                  No Earn Wage Available
                </Typography>
              </Box>
            ) : (
              <Grid container spacing={2}>
                {earnWageApproved.map((earnApp) => (
                  <Grid item xs={12} sm={6} md={4} key={earnApp?.ewage_sl }>
                    <Box
                      sx={{
                        border: '1px solid #f1f1f1',
                        background: '#f8f8f8',
                        p: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        cursor: 'pointer',
                        mt: 1,
                        borderRadius: '10px',
                        '&:hover': {
                          background: 'rgba(18, 169, 178, 0.1)'
                        }
                      }}
                      onClick={() => handleOpen(earnApp)}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            color: '#333'
                          }}
                        >
                          From: {earnApp?.first_name} {earnApp?.last_name}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontFamily: 'Poppins',
                            fontSize: '13px',
                            color: '#333'
                          }}
                        >
                          Amount: {earnApp?.draw_amount} days
                        </Typography>
                    
                      </Box>
                      <Box>
                        <CustomChip>{earnApp?.approval_status}</CustomChip>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
  
          {/* Leave Modal */}
        <EarnWageModal open={open} handleClose={handleClose} earnWageInfo={earnWageInfo} /> 
        </MainCard>
      </Box>
  );
}
